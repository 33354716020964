import React, {useEffect, useState} from "react";
import {Button, Chip, Stack, Typography} from "@mui/material";

export default function NotificationsPermissionValidator({children}) {
    const [notificationPermission, setNotificationPermission] = useState(Notification.permission);

    //automat
    useEffect(() => {
            if (!window.Notification) return
            if (Notification.permission !== 'granted') {
                Notification.requestPermission().then((permission) => {
                    setNotificationPermission(permission)
                })
            }
        }, []
    )

    //przycisk
    const handleRequestNotificationPermission = () => {
        if (!window.Notification) return
        Notification.requestPermission().then((permission) => {
            setNotificationPermission(permission);
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            } else {
                console.log('Notification permission denied.');
            }
        });
    };

    if (notificationPermission === 'granted') {
        return <>{children}</>
    }

    return (
        <Stack spacing={1}>
            <Typography variant="body1">This app requests notification permission on load.</Typography>
            <Button
                id="request-permission-button"
                variant="contained"
                color="primary"
                onClick={handleRequestNotificationPermission}
            >
                Enable Notifications
            </Button>
            <Chip label={notificationPermission}
                  color={notificationPermission === 'denied' ? 'error' : 'warning'}/>
        </Stack>
    );
}