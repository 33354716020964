import React from 'react';
import phispace192 from "./phispace192.png";
import {Divider, Stack} from "@mui/material";
import PushNotifications from "./PushNotifications/PushNotifications";
import InstallationButton from "./InstallationButton";
import WebNotificationsApi from "./Badges/WebNotificationsApi";
import ApiBadging from "./Badges/ApiBadging";
import BadgesOnIOS from "./Badges/BadgesOnIOS";


export default function HomeComponent() {
    return (
        <Stack spacing={3} sx={{width: '90vw'}} alignItems={'center'}>
            <InstallationButton/>
            <Divider flexItem/>
            <img src={phispace192} width={192} height={192} alt="logo"/>
            <Divider flexItem/>
            <PushNotifications/>
            <Divider flexItem/>
            <WebNotificationsApi/>
            <Divider flexItem/>
            {/*<SendPushNotificationButton/>*/}
            {/*<Divider flexItem/>*/}
            <ApiBadging/>
            <Divider flexItem/>
            <BadgesOnIOS/>
            <Divider flexItem/>
        </Stack>
    )
}