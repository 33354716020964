import {Button} from "@mui/material";
import React from "react";


export default function WebNotificationsApi() {


    const showNotification = () => {
        if (Notification.permission === 'granted') {
            new Notification('Nowa wiadomość (WebNotificationsApi)', {
                body: 'Aplikacja PWA 11 nowych wiadomości.',
                icon: '/phispace192.png',
            });
        }
    };


    return (
        <Button variant={'contained'} onClick={showNotification}>Pokaż powiadomienie (Web Notifications API)</Button>
    )
}