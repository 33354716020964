import {Button, Stack} from "@mui/material";
import React from "react";


export default function BadgesOnIOS() {

    if ('setAppBadge' in navigator) {
        console.log('Badging API(?) is available...')
    }

    async function clearBadge() {
        if ('clearAppBadge' in navigator) {
            try {
                await navigator.clearAppBadge();
            } catch (error) {
                console.error('Failed to clear app badge:', error);
            }
        }
    }

    async function setBadge(count) {
        if ('setAppBadge' in navigator) {
            try {
                await navigator.setAppBadge(count);
            } catch (error) {
                console.error('Failed to set app badge:', error);
            }
        }
    }

    const addBadge = (count) => {
        setBadge(count);
    }

    const deleteBadge = () => {
        clearBadge()
    }


    return (
        <Stack spacing={1}>
            <h2>BadgesOnIOS (phone only, Badging API)</h2>
            <Button  variant={'contained'} onClick={() => addBadge(5)}>Dodaj badge (setAppBadge)</Button>
            <Button  variant={'contained'} onClick={() => deleteBadge()}>Usuń badge (clearAppBadge)</Button>
        </Stack>
    )

}