export const APP_CONFIG = {
    vapidKey: 'BOCVShSTvbWmSApj2zctNk2LUI9uKI1tof_w6jSwxTTcqtA81DeRhqba_bKsK9bjOg6Egpn4szIXY8ADzL9Aopk',
    firebaseConfig:{
        apiKey: "AIzaSyBTuz9-Q-DChDeopiFTS2pWAg8JHrDizwM",
        authDomain: "phinancereactpwa.firebaseapp.com",
        projectId: "phinancereactpwa",
        storageBucket: "phinancereactpwa.appspot.com",
        messagingSenderId: "49981261201",
        appId: "1:49981261201:web:8c47a306e7ef3ac4fe0630",
        measurementId: "G-TN719VHD49"
    }
}