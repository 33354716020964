// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import {APP_CONFIG} from "./config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBTuz9-Q-DChDeopiFTS2pWAg8JHrDizwM",
    authDomain: "phinancereactpwa.firebaseapp.com",
    projectId: "phinancereactpwa",
    storageBucket: "phinancereactpwa.appspot.com",
    messagingSenderId: "49981261201",
    appId: "1:49981261201:web:8c47a306e7ef3ac4fe0630",
    measurementId: "G-TN719VHD49"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);


export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission()
        console.log(permission)
        if (permission === 'granted') {
            const token = await getToken(messaging, {
                vapidKey: APP_CONFIG.vapidKey
            })
            console.log(token)
        }
    } catch (e) {
        console.log(e)
    }

}