import React, {useEffect} from 'react';
import {Button} from "@mui/material";

export default function ApiBadging() {
    useEffect(() => {
        // Poproś użytkownika o pozwolenie na wyświetlanie powiadomień
        if (Notification.permission !== 'granted') {
            Notification.requestPermission();
        }

        // Ustaw odznakę
        const setBadge = async () => {
            if ('setAppBadge' in navigator) {
                try {
                    await navigator.setAppBadge(11);
                } catch (error) {
                    console.error('Błąd przy ustawianiu odznaki:', error);
                }
            } else {
                console.warn('API Badging nie jest obsługiwane w tej przeglądarce.');
            }
        };

        setBadge();
    }, []);

    const showNotification = () => {
        if (Notification.permission === 'granted') {
            new Notification('Nowa wiadomość (API Badging)', {
                body: 'Masz 11 nowych wiadomości.',
                icon: '/phispace192.png',
            });
        }
    };

    return (
        <Button variant={'contained'} onClick={showNotification}>Pokaż powiadomienie (API Badging)</Button>
    );
}
