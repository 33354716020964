import React, {useEffect, useState} from "react";
import {generateToken, messaging} from "../../firebase";
import {getToken, onMessage} from "firebase/messaging";
import {Divider, Stack, TextField} from "@mui/material";
import {APP_CONFIG} from "../../config";

export default function PushNotifications() {

    const [token, setToken] = useState('-')
    const [message, setMessage] = useState('-')

    async function getToken2() {
        const token2 = await getToken(messaging, {
            vapidKey: APP_CONFIG.vapidKey
        })
        console.log(token2)
        setToken(token2)
    }


    useEffect(() => {
        getToken2()
        generateToken()
        onMessage(messaging, (payload) => {
            console.log(payload)
            setMessage(JSON.stringify(payload))
        })
    }, [])

    return (
        <Stack sx={{background: '#636572', width: '100%', p: 2}} gap={1}>
            <TextField
                value={token}
                id="outlined-read-only-input"
                label="Token"
                InputProps={{
                    readOnly: true,
                }}
                multiline={true}
                fullWidth={true}
            />
            <Divider flexItem/>
            <TextField
                value={message}
                id="outlined-read-only-input"
                label="Message"
                InputProps={{
                    readOnly: true,
                }}
                multiline={true}
                fullWidth={true}
            />
        </Stack>
    );
}

