import React from 'react';
import './App.css';
import NotificationsPermissionValidator from "./assets/NotificationsPermissionValidator";
import HomeComponent from "./assets/HomeComponent";


const App = () => {

    return (
        <div className="App">
            <header className="App-header">
                {/*<FirebaseSupportValidator>*/}
                <NotificationsPermissionValidator>
                    <HomeComponent/>
                </NotificationsPermissionValidator>
                {/*</FirebaseSupportValidator>*/}
            </header>
        </div>
    )
};

export default App;
