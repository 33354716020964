import React, {useEffect, useRef, useState} from "react";
import {Button} from "@mui/material";

export default function InstallationButton() {
    const installButtonRef = useRef(null);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState();
    useEffect(() => {

        if (window.navigator.standalone === true ||
            window.matchMedia('(display-mode: standalone)').matches) {
            setIsInstalled(true)
        }
    }, [])

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);

            // Wywołaj prompt po gestu użytkownika
            if (installButtonRef.current) {
                installButtonRef.current.style.display = 'block';
                installButtonRef.current.click(); // Automatyczne kliknięcie przycisku
            }
        };

        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };

    if (isInstalled) {
        return null
    } else {
        return (
            <Button
                ref={installButtonRef}
                onClick={handleInstallClick}
                variant={'contained'}
                color={'error'}
            >
                Zainstaluj aplikacje
            </Button>)

    }
}

